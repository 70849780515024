import React from "react"
import styled from "styled-components"
import { useGetPdfsQuery } from "../components/features/api/authApi"

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: auto;
  width: 100%;
  min-height: 100vh;
`

const Wrapper = styled.div`
  grid-area: auto/2/auto/11;
  display: flex;
  flex-direction: column;
  margin-top: 100px;

  .pdf-list-wrapper {
    margin-top: 2rem;

    table {
      display: grid;
      border-collapse: collapse;
      min-width: 100%;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @media (max-width: 767px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    thead,
    tbody,
    tr {
      display: contents;
    }

    th,
    td {
      padding: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th {
      position: sticky;
      top: 0;
      background: #6c7ae0;
      text-align: left;
      font-weight: normal;
      font-size: 1.1rem;
      color: white;
    }

    th:last-child {
      border: 0;
    }

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      color: #808080;
      @media (max-width: 767px) {
        font-size: 0.85rem;
      }
    }

    tr:nth-child(even) td {
      background: #f8f6ff;
    }
  }

  .search__container {
    display: flex;
    flex-direction: row;
    background: #f8f8f8;
    box-shadow: 0px 2.70041px 2.70041px rgba(0, 0, 0, 0.1);
    border-radius: 69.3284px;
    @media (max-width: 991px) {
      display: none;
    }
    .search__input {
      width: 100%;
      padding: 20px 24px;
      border: none;
      background-color: transparent;
      transition: transform 250ms ease-in-out;
      font-size: 14px;
      line-height: 18px;
      outline: none;
      color: var(--secondaryColor);

      backface-visibility: hidden;
    }
    .search_icon {
      display: flex;
      width: 70px;
      background-color: var(--purpleColor);
      justify-content: center;
      align-items: center;
      border-radius: 69.3284px;

      svg {
        color: #fff;
        font-size: 30px;
      }
    }
    .search__input::placeholder {
      color: rgba(87, 87, 86, 0.8) !important;
      text-transform: lowercase;
      letter-spacing: 1.5px;
    }
  }
`

function Pdfs() {
  const { data, isLoading, isError } = useGetPdfsQuery()
  //   console.log("pdf", data[0])
  const handleInputChange = (event) => {
    // console.log(event.target.value)
    // const query = event.target.value
    // const posts = data.allStrapiBlogs.nodes || []
    // const category = data.allStrapiBlogsCategories.nodes || []
    // const filteredData = posts.filter((post) => {
    //   const { title, shortDescrption } = post
    //   return (
    //     title.toLowerCase().includes(query.toLowerCase()) ||
    //     shortDescrption.toLowerCase().includes(query.toLowerCase())
    //   )
    // })
    // setState({
    //   query,
    //   filteredData,
    // })
  }
  return (
    <Container>
      <Wrapper>
        <div className="search__container">
          <input
            className="search__input"
            type="text"
            placeholder="search for pdfs by title"
            onChange={handleInputChange}
          />
        </div>
        <div className="pdf-list-wrapper">
          <table>
            <thead>
              <tr>
                <th>Id</th>
                <th>Title</th>
                <th>Pdf File Name</th>
                <th>Pdf File URL</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data[0].title_with_pdf.map((d) => {
                  return (
                    <tr>
                      <td>{d.id}</td>
                      <td>{d.title}</td>
                      <td>{d.pdf.name}</td>
                      <td>{d.pdf.url}</td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      </Wrapper>
    </Container>
  )
}

export default Pdfs
